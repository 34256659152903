import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../components/layout';

const IndexPage = () => {
  return (
    <Layout pageTitle="Welcome">
      <p>I'm learning new things and having fun.</p>
      <StaticImage
        src="../images/shutterstock-1594422202.jpg"
        alt="A top-down photo of an empty beach"
      />
    </Layout>
  );
}

export default IndexPage;
