import React from 'react';
import  { Link, useStaticQuery, graphql } from 'gatsby';
import { container, siteTitle, heading, navLinks, navLinkItem, navLinkText } from '../components/layout.module.css'

const NavLink = ({to, children}) => {
  return (
    <li className={navLinkItem}>
      <Link to={to} className={navLinkText}>{children}</Link>
    </li>
  );
}

const Layout = ({ pageTitle, children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  const title = data.site.siteMetadata.title;

  return (
    <div className={container}>
      <title>{title} | {pageTitle}</title>
      <header className={siteTitle}>{title}</header>
      <nav>
        <ul className={navLinks}>
          <NavLink to="/">Home</NavLink>
          <NavLink to="/about">About</NavLink>
          <NavLink to="/blog">Blog</NavLink>
        </ul>
      </nav>
      <main>
        <h1 className={heading}>{pageTitle}</h1>
        {children}
      </main>
    </div>
  );
}

export default Layout;
